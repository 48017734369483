import Vue from 'vue'
import mappingFields from '@/libs/mappingFields'
import router from '@/router'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from 'pusher-js'

const messages = {
  200: 'Success',
  422: 'No valid request',
  500: 'Internal Server Error',
  // Add more status codes and messages as needed
}
Pusher.logToConsole = false

const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY ?? '', {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER ?? '',
  encrypted: true,
  useTLS: true,
})

Vue.prototype.$pusher = pusher
const errorNotification = (view, error, option = {}) => {
  let errorMessage = view.$t('Unknown')
  if (error?.response?.data) {
    const errors = error.response.data
    errorMessage = ''
    if (errors?.message) {
      errorMessage += `${errors.message}\n`
    }
    if (errors?.errors) {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const key in errors.errors) {
        errorMessage += `${key} - ${errors[key]}\n`
      }
    }
  }
  view.$bvToast.toast(errorMessage, {
    variant: option.variant ?? 'danger',
    solid: option.solid ?? true,
    title: view.$t(option.title ?? 'Error'),
  })
}
const toastErrorNotification = (view, _message, httpStatus) => {
  view.$bvToast.toast(_message, {
    variant: 'danger',
    solid: true,
    title: view.$t(messages[httpStatus] ?? 'Error'),
  })
}

Vue.prototype.confirmNotification = (view, data, url, options = {}, errorNotificationAble = true) => new Promise((resolve, reject) => {
  view.$swal({
    title: view.$t(options.title ?? 'Are you sure?'),
    text: view.$t(options.text ?? "You won't be able to revert this!"),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: view.$t(options.confirmButtonText ?? 'Yes, delete it!'),
    cancelButtonText: view.$t(options.cancelButtonText ?? 'Cancel'),
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
    // eslint-disable-next-line consistent-return
  }).then(result => {
    if (result.value) {
      view.$store.dispatch(url, data).then(res => {
        resolve(res)
      }).catch(err => {
        if (errorNotificationAble) {
          errorNotification(view, err)
        }
        reject(err)
      })
    }
  })
})
Vue.prototype.sendNotification = (ctx, data, url, successText) => new Promise((resolve, reject) => {
  ctx.$store.dispatch(url, data).then(res => {
    ctx.$toast.success(ctx.$t(successText ?? res?.data?.message ?? 'Successfully saved'))
    resolve(res)
  }).catch(err => {
    // errorNotification(view, err)
    reject(err)
  })
})

Vue.prototype.errorNotification = errorNotification
Vue.prototype.toastErrorNotification = toastErrorNotification
Vue.prototype.mappingFields = mappingFields

Vue.prototype.gotoNewPage = (url, e, isBlank = false) => {
  // console.log(url)
  if (e.ctrlKey) {
    const route = router.resolve(url)
    window.open(route.href)
  } else if (isBlank) {
    const route = router.resolve(url)
    window.open(route.href)
  } else {
    router.push(url).then(r => r)
  }
}

Vue.prototype.cancelFormChanges = (ctx, fullFormName, clonedData) => {
  const getPreviousValueFromCloneData = JSON.parse(clonedData)
  ctx.$store.commit(fullFormName, getPreviousValueFromCloneData)
}

Vue.prototype.convertTZ = date => {
  const timeZone = store.state.app.timezone
  const dateInTimeZones = new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone }))

  return `${new Date(dateInTimeZones).getDate()}-${new Date(dateInTimeZones).getMonth() + 1}-${new Date(dateInTimeZones).getFullYear()}  ${new Date(dateInTimeZones).toLocaleTimeString('en-US', { timeStyle: 'medium' })} `
}

// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, ...items) {
  this.splice(index, 0, ...items)
}
