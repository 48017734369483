import transferCreateByInventoryQty from './transferCreateByInventoryQty/transfer-inventory-qty'
import permanentInventoryId from './transferCreateByInventoryQty/permanent-inventory-id'
import transferCreateByAssetId from './transferCreateByAssetId/transfer-asset-id'
import permanentByAssetId from './transferCreateByAssetId/permanent-by-asset-id'
import rentalSales from './rental-sales'
import creditNote from './credit-note'
import newRentalSales from './new-rental-sales'
import purchase from './purchase'
import transfer from './transfer'
import service from './service'
import subRent from './sub-rent'

export default [
  {
    path: 'orders',
    name: 'home-orders',
    component: () => import('@/views/main/orders/Orders.vue'),
    redirect: { name: 'home-orders-view' },
    children: [
      transferCreateByInventoryQty,
      permanentInventoryId,
      transferCreateByAssetId,
      permanentByAssetId,
      {
        path: '',
        name: 'home-orders-view',
        component: () => import('@/views/main/orders/view/OrderView.vue'),
        redirect: { name: 'home-orders-rental-sales' },
        children: [
          newRentalSales,
          rentalSales,
          creditNote,
          purchase,
          transfer,
          service,
          subRent,
        ],
      },
    ],
  },
]
