<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div class="d-flex align-items-center">
        <b-form-timepicker
          :id="name"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          :value="value"
          :locale="locale"
          :disabled="isEditable"
          :style="`height: ${height}`"
          class="l-time-pick"
          :size="theSize"
          :button-only="isOnlyButton"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
        />
        <slot name="input-extra-el" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LTimePickerInput',
  components: {
    BFormTimepicker,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: () => 'en',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isOnlyButton: {
      type: Boolean,
      default: false,
    },
    theSize: {
      type: String,
      default: 'md',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss">
.l-time-pick {
  & .form-control {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
</style>
