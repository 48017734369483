<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div class="d-flex align-items-center">
        <b-form-radio-group
          :id="name"
          :name="name"
          v-bind="getProps(field)"
          :checked="value"
          :options="field.list"
          :disabled="isEditable"
          @input="$emit('input', $event)"
        />
        <slot name="input-extra-btn" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'LRadioInput',
  components: {
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    ValidationProvider,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkRequired(field) {
      if (!field.rules) return false
      return field.rules?.split('|').includes('required')
    },
    getProps() {
      return {
        ...{},
        ...this.field.options ?? {},
      }
    },
  },
}
</script>

<style></style>
