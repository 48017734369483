import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'sub-rent',
  name: 'home-orders-sub-rent',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/sub-rent/SubRent.vue'),
  redirect: { name: 'home-orders-sub-rent-list' },
  children: [
    {
      path: '',
      name: 'home-orders-sub-rent-list',
      component: () => import('@/views/main/orders/view/sub-rent/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create',
      name: 'home-orders-sub-rent-create',
      component: () => import('@/views/main/orders/view/sub-rent/create/Create.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: ':id',
      name: 'home-orders-sub-rent-update',
      component: () => import('@/views/main/orders/view/sub-rent/update/Update.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
      redirect: { name: 'home-orders-sub-rent-create' },
      children: [
        {
          path: 'quote',
          name: 'sub-rent-request-for-quote',
          component: () => import('@/views/main/orders/view/sub-rent/update/pages/DirectRequestForQuote.vue'),
          layout: 'Home Orders Update',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'ready-quote',
          name: 'sub-rent-request-for-quote-ready',
          component: () => import('@/views/main/orders/view/sub-rent/update/pages/DirectRequestForQuoteReady.vue'),
          layout: 'Sub rent ready',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'approve',
          name: 'sub-rent-approve',
          component: () => import('@/views/main/orders/view/sub-rent/update/pages/DirectApprove.vue'),
          layout: 'Sub rent ready',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'invoice',
          name: 'sub-rent-invoice',
          component: () => import('@/views/main/orders/view/sub-rent/update/pages/DirectInvoice.vue'),
          layout: 'Sub rent ready',
          meta: CATALOG_MAIN_META,
        },
      ],
    },
  ],
}
